<template>
    <div class="row mt-4 mb-4">
        <div class="hover-blue cursor-pointer col-md-12 d-flex align-items-start align-items-md-center mb-5 px-2" @click="goBack">
            
            <div class="me-3 mt-2 mt-md-0">
                <icon-arrow :size="25" style="transform: rotate(180deg);" color="var(--dark-gray-200)"/>
            </div>
            <div class="d-flex flex-column align-items-start ms-1">
                <h6 class="title-22 color-blue-700 mb-1">{{$t("account.my_addresses")}}</h6>
                <h6 class="subtitle-16 mb-0">{{$t("account.description_my_addresses")}}</h6>
            </div>

        </div>
        <div class="col-lg-4 col-md-6">
            <div class="add-addresss d-flex justify-content-center align-items-center d-flex flex-column" @click="addressEditData = null; showAddressModal = true" :class="[hover ? 'add-addresss-hover' : '']"
            @mouseover="hover = true" @mouseleave="hover = false">
                <icon-add-addresses :size="60" :hover="hover"/>
                <h5 class="mb-0 title-18">{{ $t("account.add_address") }}</h5>
            </div>
        </div>
        <div :key="address.id" v-for="(address, index) in addresses" class="col-lg-4 col-md-6" :class="[index > 1 ? 'card-container' : '', index === 0 ? 'pt-sm-4 pt-md-0 pt-4' : '', index === 1 ? 'pt-sm-4 pt-md-4 pt-lg-0 pt-4' : '']">
            <card class="p-4 card-addresses position-relative" style="max-width: 360px;" @click.native="changeAddress($event, address)" id="card-address">
                <ul class="list-group" style="list-style: none;">
                    <li class="d-flex align-items-center justify-content-between mb-3"> 
                        <radio-button :id="'selected-address-'+address.id" :checked="addressSelected.id === address.id"/>
                        <icon-close :size="17" :color="'var(--gray-1001)'" @click.native="deleteAddress(address)"/>
                    </li>
                    <li class="d-flex align-items-center justify-content-between subtitle-15 mb-2" >
                        <div class="d-flex align-items-center">
                            <icon-warning :size="16" v-if="!addressIsAvailable(address)" class="me-2 icon-warning-out-range"/>
                            <popup class="out-range color-main-red size-13 px-2 py-1" :style="{top: `10%`, left: `-13%`, 'border-radius': `6px`}">
                                Direccion fuera de rango
                            </popup>
                            <span class="text-limited title-16 mb-0 text-capitalize">{{ address.name | textFormat }} </span>
                        </div>
                        <span v-if="address.default" class="title-15 weight-500 mb-0 d-flex align-items-center"><icon-star class="me-2" :size="17"/>{{ $t("account.default")}}</span>
                    </li>
                    <li class="subtitle-15 text-limited color-dark-gray-200"><span class="title-15 mb-0 weight-500">Tel:</span> {{ address.phone | phoneFormat }}</li>
                    <li class="subtitle-15 text-limited text-capitalize"> {{ address.address_line | textFormat}}</li>
                    <li class="subtitle-15 text-limited"><span class="title-15 mb-0">Ref:</span> {{ address.reference | textFormat}}</li>
                    <li class="d-flex mt-4 align-items-end" :class="[address.default ? '' : 'justify-content-center']"> 
                        <a href="#" @click.prevent="deleteAddress(address)">{{ $t("account.remove") }}</a>
                        <span class="mx-3 mx-lg-2 mx-xl-3 color-dark-gray-200">|</span>
                        <a href="#" @click.prevent="editAddress(address)" >{{ $t("account.edit") }}</a>
                        <span class="mx-3 mx-lg-2 mx-xl-3 color-dark-gray-200" v-if="!address.default">|</span>
                        <a href="#" v-if="!address.default" @click.prevent="defaultAddress(address.id)" >{{ $t("account.default") }}</a>
                    </li>
                </ul>
            </card>
        </div>
        <alert-one-address v-if="oneAddress" @closeModal="oneAddress = false"/>
        <confirm-delete-address v-if="showConfirmDelete" @closeModal="showConfirmDelete = false" @confirmDelete="deleteConfirmed"/>
        <create-address v-if="showAddressModal" @closeModal="showAddressModal = false" :addressEditData="addressEditData"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import Card from '../../../common/components/cards/Card.vue'
import Popup from '../../../common/components/popup/Popup.vue'
import RadioButton from '../../../common/components/radio/RadioButton.vue'
import IconArrow from '../../../common/svg/iconArrow.vue'
import IconClose from '../../../common/svg/iconClose.vue'
import IconStar from '../../../common/svg/iconStar.vue'
import IconWarning from '../../../common/svg/iconWarning.vue'
import IconAddAddresses from '../assets/svg/iconAddAddresses.vue'
import AlertOneAddress from '../components/addresses/modals/AlertOneAddress.vue'
import ConfirmDeleteAddress from '../components/addresses/modals/ConfirmDeleteAddress.vue'
import CreateAddress from '../components/addresses/modals/CreateAddress.vue'
import { addressesMixin } from '../helpers/mixins/addresses-mixin'

export default {
    name: "Addresses",
    components: {
        CreateAddress,
        ConfirmDeleteAddress,
        Card,
        IconStar,
        AlertOneAddress,
        IconAddAddresses,
        RadioButton,
        IconClose,
        IconArrow,
        IconWarning,
        Popup,
    },
    data() {
        return {
            showAddressModal: false,
            showConfirmDelete: false,
            addressDelete: {},
            oneAddress: false,
            addressEditData: null,
            hover: false,
            changingDefault: false
        }
    },
    mixins: [addressesMixin],
    computed:{
        ...mapGetters({
            addresses: "account/getAddresses",
            addressSelected: "account/addressSelected",
        }),
    },
    methods: {
        goBack(){
            this.$router.go(-1)
        },
        changeAddress(event, address){
            if(address.id != this.addressSelected.id && event.target.tagName !='A'
            && event.target.tagName !='svg' && event.target.tagName !='path')
            this.$store.dispatch('account/setAddress', address)
        }
    }
}
</script>

<style scoped>
    .card-address:hover > div > div > h5{
        color: var(--light-main-red);
    }
    a{
        font-size: 14px;
    }
    .add-addresss{
        min-height: 230px;
        max-width: 360px;
        margin: 0 auto;
        cursor: pointer;
        transition: var(--transition-1);
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23A5B2B7FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 15px;
    }
    .add-addresss-hover{
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23DAE5EAFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 15px;
    }
    .add-addresss > svg {
        transition: var(--transition-1) !important;
    }
    .add-addresss:hover > svg > .plus{
        fill: var(--dark-main-red) !important;
        cursor: pointer;
    }
    .card-addresses{
        transition: var(--transition-1);
    }
    .card-addresses:hover{
        border-color: var(--gray-100) !important;
        box-shadow: none;
    }
    .card-container{
        padding-top: 24px;
    }
    @media (min-width: 1400px) {
        .card-container{
            padding-top: 75px;
        }
    }
    .hover-blue svg{
        transition: var(--transition-1);
    }
    .hover-blue:hover svg{
        fill: var(--blue-600);
    }
    .icon-close::v-deep:hover > path{
        fill: var(--dark-gray-200);
        cursor: pointer;
    }
    .icon-warning-out-range:hover ~ .out-range{
        display: block;
    }
    .out-range{
        display: none;
    }
    .out-range::before{
        top: 100%;
        right: 50%;
        transform: translate(50%, -50%) rotate(136deg);
        width: 12px;
        height: 12px;
    }
</style>